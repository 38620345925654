@import '../../../scss/variables';

.configuration_view{
     box-sizing: border-box;
     min-height: 100vh;
     padding-top: 8px;
}
.imgbookingdesk{
     width: 242.07px;
     height:auto;
}

.imgbookingdesk_welcome_msg{
     font-weight: 700;
     font-size: 18px;
     line-height: 24px;
     margin-top: 32px;
}

.imgbookingdesk_welcome_msg2{
     font-weight: 400;
     font-size: 14px;
     line-height: 20px;
     margin-top: 8px;
}