.cn-mini-calendar {
    font-family: 'Segoe UI', arial, sans-serif;
    font-size: 14px;
    padding-left: 5px;
    .cn-mini-calendar-label {
        display: inline-block;
        vertical-align: top;
        margin-left: 5px;
        line-height: 2.3;

        .cn-mini-calendar-label-bold {
            font-weight: 700;
        }
        
        .cn-mini-calendar-label-semibold {
            font-weight: 600;
        }
    }

    .cn-mini-calendar-nav {
        width:24px;
        padding: 3px 0;
        background: #f5f5f5;
        border-color: #f5f5f5;
        img {
            height: 22px;
        }
        &:first-child {
            margin-right: 5px;
        }
        &:focus, &:hover {
            box-shadow: none;
        }
    }
}
