@import '../../../scss/variables';

.book_workspace_avail {
    font-family: $font_family;
    padding: 30px 32px 0px 32px;

    p {
        line-height: 19px;
        strong {
            font-weight: 600;
        }
    }

    :global(.form-label) {
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        margin-bottom: 6px;
    }
    .availwslabel{
        margin-bottom: 12px;
    }

    fluent-select {
        width: 100%;
        background: #F0F0F0;
    }

    .book_workspace_avail__frm {
        > div {
            margin-bottom: 20px;
        }
        
        .book_workspace_avail__date_pills {
            &, li {
                margin: 0;
                padding: 0;
                list-style-type: none;
            }
            li {
                overflow: hidden;                
                display: inline-block;
                margin-right: 12px;
                background-color: #D9D9D9;
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
                padding: 4px 12px;
                border-radius: 70px;
                min-width: 80px;
            }
        }        
    }

    .book_workspace_avail__actionRow {
        padding: 26px 0 29px 0;
        margin-top: 36px;

        fluent-button {
            background: $lightColor;
            --neutral-stroke-rest: $primaryColor;
            --neutral-stroke-hover: $primaryColor;
            --neutral-stroke-focus: $primaryColor;
            --neutral-stroke-active: $primaryColor;
            color: $primaryColor;
            font-size: 14px;
            font-weight: 600;
            margin-left: 10px;
            margin-bottom: 5px;
        }
    }
    a{
        text-decoration: none;
        padding-left: 5px;
        font-weight: 600;
    }
}