@import '../../../scss/variables';

.page-submit-schedule {
    position: relative;
    padding: 36px 32px 75px;
    min-height: 100vh;
    box-sizing: border-box;
    font-family: $font_family;
    font-weight: $bodyWeightNormal;

    fluent-button {
        font-size: $bodyFontSize;
        font-weight: $bodyWeightBold;        
        color: $primaryColor;        
        background: $lightColor;
        --neutral-stroke-rest: $primaryColor;
        --neutral-stroke-hover: $primaryColor;
        --neutral-stroke-focus: $primaryColor;
        --neutral-stroke-active: $primaryColor;
        &:global(.disabled){            
            color: #8f8f8f;
            --neutral-stroke-rest: #8f8f8f;
            --disabled-opacity: 1;
        }
    }

    fluent-checkbox {
        --accent-fill-rest: #5B5FC7;
        --accent-fill-hover: #5B5FC7;
        --accent-fill-active: #5B5FC7;
        --neutral-fill-input-alt-rest: transparent;
        --neutral-fill-input-alt-hover: transparent;
        --neutral-stroke-strong-rest: #666;
    }

    @media screen and (max-width: 572px) and (orientation: portrait ) {
        :global(.fluentcheckboxlabel.text-truncate) {
            max-width: 80px;
        }

        & {
            padding: 16px 16px 75px;
        }
    }
    
    @media screen and (max-width: 430px) {
        & {
            padding: 16px 16px 75px;
        }
    }

    .error-task-content {
        line-height: 20px;

        .error-task-content-text:not(:last-child) {
            margin-bottom: 24px;
        }
    }

    .submit-shedule-comp-title {
        color: $bodyBoldColor;
        margin-bottom: 24px;
    }

    .booking-confirmation-hdr-sec {
        // margin-bottom: 40px;
        .submit-shedule-comp-title {
            margin-bottom: 18px;
            line-height: 24px;
        }

        .desks-name {
            font-size: 24px;
            line-height: 32px;
            font-weight: 350;
        }

        .floor-name, .location-name {
            line-height: 19px;
        }
    }

    .form-action {
        position: absolute;
        left: 32px;
        right: 32px;
        bottom: 24px;

        fluent-button:not(:last-child) {
            margin-right: 10px;
        }
    }

    .days {
        .day-list {
            margin-top: 14px;

            :global(.list-group-item) {
                padding: 0;

                &:not(:last-child) {
                    margin-bottom: 12px;
                }
            }
        }

        .people-ui {
            --list-margin: 0;
            --avatar-margin: 0 -6px 0 0;
        }
    }

    .location-block {
        margin-bottom: 16px;

        :global(.locationcombobox) {
            margin: 0 -1px;
        }
    }

    .days-checklist-block, .day-list-has-nochkbx {
        margin-bottom: 24px;
    }

    .submitScheduleBottomMsg, .submit-shedule-comp-top-des, .bkws-btm-msg {
        color: $bodyColor;
    }

    .submit-shedule-comp-top-des, .bkws-btm-msg, .submitScheduleBottomMsg {
        line-height: 20px;
    }

    .submit-shedule-comp-top-des {
        &.submit-shedule-bkws {
            margin-bottom: 16px;
        }
    }

    &.set-status-view {
        .submit-shedule-comp-title {
            font-size: $fontSizeXLarge;
            line-height: 24px;
            margin-bottom: 16px;
        }

        .booking-confirmation-hdr-sec {
            .submit-shedule-comp-title {
                margin-bottom: 20px;
            }
        }

        .day-check-list {
            margin: 36px 0;

            .day-text-wrap {
                .img-check-mark {
                    margin-right: 8px;
                }

                > div {
                    line-height: 20px;
                }
            }
        }

        .submit-shedule-comp-top-des {
            .fw-bold {
                font-weight: 700;
            }
        }
    }

    a {
        text-decoration: none;
        padding-left: 5px;
        font-weight: 600;
    }
}