$bodyFontSize: 14px;
$fontSizeSmall: 12px;
$fontSizeLarge: 16px;
$fontSizeXLarge: 18px;
$bodyWeightNormal: 400;
$bodyWeightBold: 600;

$bodyColor: #242424;
$bodyBoldColor: #333333;
$primaryColor: #5B5FC7;
$lightColor: #fff;
$font_family: 'SegoeUI', arial, sans-serif;