@import '../../../scss/variables';

.tabview_cal__bg {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: #f5f5f5;
    z-index: 0;
    margin-bottom: -19px;
}

.tabview_cal_defaultLocationMsg{
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 1;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
}

.tabview_cal {
    position: relative;
    z-index: 1;
    --neutral-stroke-divider-rest: #e3e3e3;
    .tabview__cal_schedule {
        padding: 25px 31px 31px 31px;
       > :global(.row > .col) {
            margin-bottom: 20px;
            --bs-gutter-x: 0.9rem;
       }
    }

    fluent-design-system-provider {
        position: sticky;
        width: 100%;
        top: 0;
        z-index: 3;
        padding: 0;
        &, fluent-toolbar {
            background: #f5f5f5;
        }
        .tabview_cal__btnbook {
            font-weight: 600;
            img {
                margin-right: 5px;
                width: 19px;
                vertical-align: bottom;
            }
        }

        fluent-toolbar {
            padding: 7px 20px;
            width: 100%;
            height: 48px;
        }
    }

    @media screen and (max-width: 572px) {
        .tabview__cal_schedule {
            padding: 16px 16px 20px 16px;
            > :global(.row > .col) {
                    margin-bottom: 35px;
            }
        }
    }
}

.weekday_col {
    text-align: center;
    h2 {
        margin-bottom: 12px;
        font-size: 16px;
        font-weight: 700;
        color: #333;
        padding-bottom: 2px;
        span {
            font-weight: 600;
        }
    }

    .weekday_col__bkstatus {
        min-height: 94px;
        padding-bottom: 10px;
        position: relative;
        --bs-link-color: #5B5FC7;
        .weekday_col__bkstatus_text {
            margin: 0;
            margin-bottom: 12px;
            font-size: 14px;
            font-weight: 400;
            color: #333;
            line-height: 18px;
            margin-top: -2px;
            span {
                color: #5B5FC7;
                font-weight: 600;
                &.previous_day_location{
                    color:#333;
                }
            }
        }

        .weekday_col__bkstatus_text_deskdesc{
            margin-bottom: 0;
        }

        .weekday_col__deldesk {
            font-size: 14px;
            font-weight: 400;
            margin-bottom: 5px;            
            overflow: hidden;
            margin-top: -4px;
                         
            div {
                line-height: 18px;
                text-align: left;
                position: relative;
                > span:first-child {
                    display: inline-block;
                    position: absolute;
                    left: 0;
                    top: 0;
                }

                .weekday_col__deldesk_label {
                    display: block;
                    padding-left: 32px;
                    padding-top: 4px;
                    line-height: 19px;
                }
            }
            
            img {
                width: 15px;
            }
            button {
                background: #f5f5f5;
                border-color: #f5f5f5;      
                padding-bottom: 0;
                padding-top: 0;
                &:hover {
                    opacity: 0.8;
                }
            }
        }
        .weekday_col__action_area {
            fluent-button {
                background: $lightColor;
                --neutral-stroke-rest: $primaryColor;
                --neutral-stroke-hover: $primaryColor;
                --neutral-stroke-focus: $primaryColor;
                --neutral-stroke-active: $primaryColor;
                color: $primaryColor;
                font-size: 14px;
                font-weight: 600;
            }

            &.weekday_col__btnWorkspace {                
            }
        }
    }

    fluent-card {
        text-align: left;
        border-radius: 8px;
        box-shadow: none;
        border-color: #f5f5f5;
        h3 {
            font-size: 13px;
            font-weight: 600;
            line-height: 2.7;
            min-height: 36px;
            margin-bottom: 0;
            text-align: center;
        }

        .weekday_col__teamMember {
            padding: 9px 10px;
        }
    }

    .weekday_col__people_list:global(.list-group .list-group-item) {
        padding: 3px 0;        
        border: 0 none;
        &:first-child {
            padding-top: 0;
        }
        &:last-child {
            padding-bottom: 0;
        }
    }

    .weekday_col__person {
        font-family: $font_family;
        --color-sub1: #333;
        --font-size: 12px;
        --font-weight: 400;
    }
    
    @media screen and (max-width: 572px){
        text-align: left;

        .weekday_col__bkstatus {
            min-height: 0;
        }
    }
}
.admin_consent_alert{    
    --bs-alert-color: #333;
    --bs-alert-bg: #E0E0E0;
    --bs-alert-border-color: #D1D1D1;    
    height: 44px;
    font-weight: 600;
    font-size: 14px;
    line-height: 0.7;
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    margin-left: -10px;
    margin-right: -10px;

    fluent-anchor {
        --stroke-width: 0;
        border: 1px solid #D1D1D1;
    }

    > span {
        flex: 1;
        flex-grow: 8;
        &:last-child {
            flex-grow: 1.5;
            text-align: right;
        }
    }

    :global(.btn-close) {
        top: -4px;
        font-size: 12px;
    }
}