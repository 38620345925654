@import './_variables';

html, body, h1, h2, h3, h4, h5, span, label, p, div, button, strong, i, b, em {
    font-family: $font_family;
    color: #333;
}

body {
    font-size: $bodyFontSize;
    color: $bodyColor;
    //  background-color: #F5F5F5;
    // background-color: #000;
}

a {
    text-decoration: none;
    color: #5B5FC7;
}

.no_display {
    display: none !important;
}

.no_visibility {
    visibility: hidden !important;
}

.no_scroll {
    overflow: hidden !important;
}

.clear_floats {
    clear: both;
}

.truncated-ellipsis-text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

fluent-button {
    --body-font: $font_family;
}

fluent-checkbox {
    &:focus-visible {
        --focus-stroke-outer: #5b5fc7;
    }
}

.ent-teams-app{
    .btn-outline-primary {
        color: $primaryColor;
        border-color: $primaryColor;
        &:hover {
            color: $lightColor;
            background-color: $primaryColor;
        }
    }
    .form-label, .font-weight-bold{
        color: $bodyBoldColor;        
        font-weight: $bodyWeightBold; 
    }    
    .form-label{
        margin-bottom: 0;
        padding: 4px 0;               
        line-height: 18px;
    }
    .fluent-select {
        border-color: #F0F0F0;
        background: #F0F0F0;
        width: 100%;
        border-radius: 0;
        --max-height: 200px;
        --body-font: 'SegoeUI', arial, sans-serif;
        --neutral-foreground-rest: #242424;
        --elevation-shadow-flyout: 0px 0.6px 1.8px rgba(0, 0, 0, 0.11), 0px 3.2px 7.2px rgba(0, 0, 0, 0.13);
        fluent-option{
            --neutral-fill-secondary-rest: #F5F5F5;
            --body-font: 'SegoeUI', arial, sans-serif;
            --neutral-foreground-rest: #242424;
        }
    }    
    .btn:not(.btn-sm, .btn-lg), .form-control, .form-select{
        padding-top: 0;
        padding-bottom: 0;
        height: 32px;
    }   
    .btn{
        font-weight: $bodyWeightBold;
        border-radius: 4px;
    } 
    .btn, .form-select, .form-control{
        font-size: $bodyFontSize;
    }
    .form-control, .form-select{
        border-radius: 2px;
    }
    .day-checkbox-list{
        .day-list-label{
            padding: 1px 0;               
            line-height: 18px;
        }
        .day-list-group{
            margin-top: 14px;
            .list-group-item{
                padding: 0;
                &:not(:last-child){
                    margin-bottom: 12px;
                }
                .day-list-col{
                    line-height: 1;
                    &:first-child{
                        padding: 2px 0;
                    }
                }
            }            
            .people-ui{
                --list-margin: 0;
                --avatar-margin: 0 -6px 0 0;           
            } 
        }
    }
}